<template lang="pug">
.card.card__content(
  v-if="card && !card?.link"
  :class="{[`card--${theme}`]: theme,'card--link': card.link}"
  @click="onCardClick()"
)
  Icon.card__icon(
    v-if="card.icon"
    :icon="card.icon"
    :theme="theme"
  )
  |
  .card__header
    .card__title(v-if="card.title") {{ card.title }}
    |
    IconArrowNarrowRight.card__arrow(v-if="card.link")
  |
  .card__description(v-if="card.description" v-html="card.description")
  |
  .card__button(v-if="card.button")
    NuxtLink(class="btn btn-primary" :to="card.button.to") {{ card.button.label }}
|
NuxtLink.card.card__content(
  v-if="card && card?.link"
  :class="{[`card--${theme}`]: theme,'card--link': card.link}"
  :to="card?.link"
)
  Icon.card__icon(
    v-if="card.icon"
    :icon="card.icon"
    :theme="theme"
  )
  |
  .card__header
    .card__title(v-if="card.title") {{ card.title }}
    |
    IconArrowNarrowRight.card__arrow(v-if="card.link")
  |
  .card__description(v-if="card.description" v-html="card.description")
  |
  .card__button(v-if="card.button")
    NuxtLink(class="btn btn-primary" :to="card.button.to") {{ card.button.label }}
</template>

<script setup lang="ts">
import type { TCard } from "@/models/card";
import { IconArrowNarrowRight } from '@tabler/icons-vue';
import { useRouter } from 'vue-router';

const router = useRouter();

interface IProps {
  card?: TCard | null;
  theme?: string;
}

const props = withDefaults(defineProps<IProps>(), {
  card: null,
  theme: "primary",
});

// async function onCardClick() {
//   if (!props.card?.link) return;

//   try {
//     await router.push(props.card.link);
//   } catch (error) {
//     console.error("Navigation error:", error);
//   }
// }
</script>

<style lang="sass" scoped>
.card
  border-radius: 12px
  display: flex
  flex-direction: column
  align-items: flex-start
  flex-shrink: 0

  &--primary
    background: var(--darkilimegreen-50, #EBF0E1)
    padding: 24px
    gap: 16px

    .card__icon
      margin-bottom: 8px

  &--secondary
    background: transparent
    gap: 12px

    .card__icon
      margin-bottom: 12px
      background: var(--raspberrypink-700, #A01847)

    .card__title
      font-size: 20px
      line-height: 24px
      font-weight: 700

  &--link
    min-height: 180px
    cursor: pointer
    transition: 0.3s
    min-width: 368px

    .card__arrow
      transform: translateX(0)
      transition: transform 0.3s ease
      
    &:hover .card__arrow
      transform: translateX(5px)

    .card__header
      flex: 1

    .card__title
      font-size: 18px
      line-height: 26px
      font-weight: 700
      max-width: 160px

  &__icon
    border-radius: 8px
    background: var(--limegreen-200, #B5F23C)
    padding: 10px

  &__header
    display: flex
    justify-content: space-between
    align-items: flex-end
    width: 100%

  &__title
    color: #034033
    font-size: 24px
    font-style: normal
    font-weight: 800
    line-height: 29px

  &__description
    color: var(--tealgreen-900, #00261E)
    font-size: 18px
    font-style: normal
    font-weight: 500
    line-height: 26px

    & :deep(ul)
      list-style: disc
      list-style-position: outside
      padding-left: 0
      margin-left: 20px

  &__button
    flex: 1
    display: flex
    align-items: flex-end
    width: 100%
    margin-top: 14px

    & :deep(.btn)
      width: 100%

@media (max-width: 1440px)
  .card
    &--link
      min-width: auto

@media (max-width: 768px)
  .card
    &__icon
      margin-left: auto
      margin-right: auto

    &__header
      justify-content: center

    &--primary
      min-width: auto
      padding: 48px 16px 16px 16px

    &--link
      padding: 24px
      min-width: unset

      .card
        &__icon
          margin: 0

        &__header
          justify-content: space-between

        &__title
          text-align: left
</style>